.variant-row {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.form-label-custom {
    font-weight: 500;
    /* Slightly lighter than 600 for a more modern feel */
    color: #212529;
    /* Dark gray for better contrast */
    line-height: 1.5;
    /* Improved readability */
    letter-spacing: 0.02em;
    /* Subtle letter spacing for clarity */
    font-family: "Roboto", sans-serif;
    /* A popular and versatile sans-serif font */
}

.variant-inputs .form-control {
    width: 100%;
}

.remove-button {
    width: 100%;
}

@media (min-width: 576px) {
    .variant-inputs .form-control {
        width: auto;
    }

    .remove-button {
        width: auto;
    }
}